import { useEffect, useState } from 'react';

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	useEffect(() => {
		let timeout = 0;
		function handleResize() {
			clearTimeout(timeout);
			timeout = setTimeout(
				() =>
					setWindowSize({
						width: window.innerWidth,
						height: window.innerHeight,
					}),
				60
			);
		}

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			clearTimeout(timeout);
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return windowSize;
};

export default useWindowSize;
