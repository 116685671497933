/** @jsxImportSource @emotion/react */
import { ThemeProvider, Global } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';

import ApiProvider from './api/provider';
import { PageNavigationProvider } from './components/navigation/usePageNavigation';

import ErrorBoundary from './ErrorBoundary';
import PageWrap from './views/PageWrap';

import { theme, globalStyles } from './theme';

import Header from './components/layout/Header';
import Navigation from './components/navigation/Navigation';

const App = () => {
	return (
		<HelmetProvider>
			<ThemeProvider theme={theme}>
				<Global styles={globalStyles} />
				<ErrorBoundary>
					<ApiProvider>
						<PageNavigationProvider>
							<Header>
								<Navigation />
							</Header>
							<PageWrap />
						</PageNavigationProvider>
					</ApiProvider>
				</ErrorBoundary>
			</ThemeProvider>
		</HelmetProvider>
	);
};

export default App;
