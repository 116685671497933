/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FocusScope } from 'react-aria';
import { RemoveScroll } from 'react-remove-scroll';
import { useLocation, Link } from 'react-router-dom';

import usePageNavigation from './usePageNavigation';
import { Typography } from '../typography';
import Header from '../layout/Header';
import Burger from './Burger';
import Container from '../layout/Container';
import { usePage } from '../../api/use';

const Section = ({ level = 1, id, hash, label, tint, subItems, hiliteId, hiliteIdSub }) => {
	const ref = useRef();
	const isCurrent = useMemo(
		() => (level === 1 && hiliteId === id) || (level === 2 && hiliteIdSub === id),
		[level, id, hiliteId, hiliteIdSub]
	);
	useEffect(() => {
		if (isCurrent) {
			ref.current.scrollIntoView();
		}
	}, [isCurrent, ref]);
	return (
		<li
			ref={ref}
			css={[
				css`
					margin: 0;
					padding: 0;
				`,
				level === 2
					? css`
							& + li {
								display: inline;

								& > a:before {
									content: ' / ';
								}
							}
					  `
					: css`
							margin: 3em 0;
					  `,
			]}
		>
			<Typography
				as={Link}
				to={'#' + hash}
				font="caption"
				weight="bold"
				size={level === 1 ? 'xl' : 'l'}
				css={(theme) =>
					css`
						text-decoration: ${isCurrent ? 'underline' : 'none'};
						color: ${theme.color[tint + '1']};

						&:visited {
							color: ${theme.color[tint + '1']};
						}
					`
				}
			>
				{label}
			</Typography>
			{!!subItems && !!subItems.length && (
				<ul
					css={css`
						list-style: none;
						margin: 0;
						padding: 0;
					`}
				>
					{subItems.map((it) => (
						<Section
							key={it.id}
							id={it.id}
							level={level + 1}
							hiliteId={hiliteId}
							hiliteIdSub={hiliteIdSub}
							label={it.label}
							tint={it.tint}
							hash={it.hash}
						/>
					))}
				</ul>
			)}
		</li>
	);
};

const NavigationContent = ({ enabled, onClose }) => {
	const { items, subItems, hiliteId, hiliteIdSub } = usePageNavigation();

	return (
		!!enabled && (
			<RemoveScroll
				as="div"
				aria-hidden={!enabled}
				enabled={enabled}
				css={(theme) => css`
					pointer-events: all;
					position: absolute;
					top: 0;
					left: 0;
					width: ${enabled ? '100vw' : 0};
					height: ${enabled ? '100vh' : 0};
					overflow: auto;
					z-index: ${theme.zIndex.mobileNav};
					background-color: ${theme.color.bg};
				`}
			>
				<Header isFixed={false}>
					<Burger isOpen onClick={onClose} />
				</Header>
				<Container>
					<ul
						css={css`
							text-align: center;
							list-style: none;
							margin: 3em 0 0 0;
							padding: 0 0 2em;
						`}
					>
						{items.map((it) => (
							<Section
								key={it.id}
								id={it.id}
								label={it.label}
								tint={it.tint}
								hash={it.hash}
								subItems={it.noSubItems ? null : subItems[it.id]}
								hiliteId={hiliteId}
								hiliteIdSub={hiliteIdSub}
							/>
						))}
					</ul>
				</Container>
			</RemoveScroll>
		)
	);
};

const Navigation = () => {
	const {
		vitality: { loaded },
	} = usePage();
	const [showDialog, setShowDialog] = useState(false);
	const open = useCallback(() => setShowDialog(true), [setShowDialog]);
	const close = useCallback(() => setShowDialog(false), [setShowDialog]);
	const location = useLocation();

	useEffect(() => {
		setShowDialog(false);
	}, [location, setShowDialog]);

	return (
		!!loaded && (
			<div>
				<Burger isOpen={false} onClick={open} />
				<FocusScope contain={showDialog}>
					<NavigationContent enabled={showDialog} onClose={close} />
				</FocusScope>
			</div>
		)
	);
};

export default memo(Navigation);
