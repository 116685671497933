import { css } from '@emotion/react';

//import slideshowStyles from './components/slideshow/styles';

/*
 * maps {key: value} to {key:'var(--NAME-KEY)'}
 */
function applyObjToTheme(theme, obj, name, prefix = '') {
	theme[name] = {};
	Object.keys(obj).forEach((k) => {
		if (typeof obj[k] === 'object') {
			theme[name][k] = {};
			applyObjToTheme(theme[name], obj[k], k, `${prefix}${name}-`);
		} else {
			theme[name][k] = `var(--${prefix}${name}-${k})`;
		}
	});
}

/*
 * maps {key: value} to '--NAME-KEY: value
 */
function applyObjToCss(obj, name) {
	return Object.keys(obj)
		.map((k) => {
			if (typeof obj[k] === 'object') {
				return applyObjToCss(obj[k], `${name}-${k}`);
			}
			return `--${name}-${k}: ${obj[k]};`;
		})
		.join('');
}

export let theme = {};

theme.pxToEm = (px) => px / 16 + 'em';
theme.pxToUnit = theme.pxToEm;
theme.pxToPercent = (px) => (100 / 16) * px + '%';
theme.pxToFontSize = theme.pxToPercent;

// css vars can not be used in media queries
theme.breakpoint = {
	mobileNav: theme.pxToEm(768),

	xs: theme.pxToEm(10),
	s: theme.pxToEm(640),
	m: theme.pxToEm(768),
	l: theme.pxToEm(1024),
	ml: theme.pxToEm(1200),
	xl: theme.pxToEm(1920),
};

const dimension = {
	container: {
		padding: { s: '0 1.2em', m: '0 1.4em', l: '0 2em' },
		margin: { s: '2.6em', m: '2.6em', l: '3em' },
	},
	spacing: {
		s: theme.pxToEm(40),
		m: theme.pxToEm(50),
		l: theme.pxToEm(60),
	},
	gap: {
		s: theme.pxToEm(12),
		m: theme.pxToEm(16),
		l: theme.pxToEm(20),
	},
	maxWidth: theme.pxToEm(1400),
	maxWidthNarrow: theme.pxToEm(760),
	element: {
		margin: {
			top: {
				s: theme.pxToEm(20),
				m: theme.pxToEm(40),
				l: theme.pxToEm(60),
			},
			bottom: {
				s: theme.pxToEm(10),
				m: theme.pxToEm(20),
				l: theme.pxToEm(30),
			},
		},
	},
};
applyObjToTheme(theme, dimension, 'dimension');

const zIndexes = ['content', 'pageNav', 'mobileNav', 'header', 'lightbox', 'loadingScreen'];
const zIndex = {};
zIndexes.forEach((k, i) => {
	zIndex[k] = (i + 1) * 10;
});
applyObjToTheme(theme, zIndex, 'zIndex');

const font = {
	default: {
		family: '"FFScala", serif',
		weight: {
			regular: 400,
		},
		size: {
			s: {
				s: theme.pxToPercent(14),
				m: theme.pxToPercent(14),
				l: theme.pxToPercent(16),
			},
			m: {
				s: theme.pxToPercent(16),
				m: theme.pxToPercent(20),
				l: theme.pxToPercent(24),
			},
			l: {
				s: theme.pxToPercent(32),
				m: theme.pxToPercent(38),
				l: theme.pxToPercent(64),
			},
			xl: {
				s: theme.pxToPercent(42),
				m: theme.pxToPercent(76),
				l: theme.pxToPercent(128),
			},
			xxl: {
				s: theme.pxToPercent(60),
				m: theme.pxToPercent(120),
				l: theme.pxToPercent(200),
			},
		},
		lineHeight: {
			s: {
				s: 1.25,
				m: 1.25,
				l: 1.25,
			},
			m: {
				s: 1.25,
				m: 1.25,
				l: 1.25,
			},
			l: {
				s: 1.25,
				m: 1.25,
				l: 1.25,
			},
			xl: {
				s: 1.25,
				m: 1.25,
				l: 1.25,
			},
			xxl: {
				s: 1,
				m: 1,
				l: 1,
			},
		},
	},
};

font.caption = {
	...font.default,
	family: 'DINNext, sans-serif',
	weight: {
		regular: 400,
		medium: 600,
		bold: 800,
	},
	lineHeight: {
		...font.default.lineHeight,
		s: {
			s: 1.05,
			m: 1.05,
			l: 1.05,
		},
		m: {
			s: 1.05,
			m: 1.05,
			l: 1.05,
		},
		l: {
			s: 1.05,
			m: 1.05,
			l: 1.05,
		},
		xl: {
			s: 1.05,
			m: 1.05,
			l: 1.05,
		},
	},
};

applyObjToTheme(theme, font, 'font');
theme.typography = {
	getStyle: ({
		theme,
		size = 'm',
		weight = 'regular',
		font = 'default',
		style = 'normal',
		decoration = 'initial',
		lineHeight,
	}) => {
		return css`
			font-family: ${theme.font[font].family};
			font-size: ${theme.font[font].size[size].s};
			font-weight: ${theme.font[font].weight[weight]};
			font-style: ${style};
			line-height: ${lineHeight || theme.font[font].lineHeight[size].s};
			text-decoration: ${decoration};

			@media (min-width: ${theme.breakpoint.m}) {
				font-size: ${theme.font[font].size[size].m};
				line-height: ${lineHeight || theme.font[font].lineHeight[size].m};
			}

			@media (min-width: ${theme.breakpoint.l}) {
				font-size: ${theme.font[font].size[size].l};
				line-height: ${lineHeight || theme.font[font].lineHeight[size].l};
			}
		`;
	},
};

export const colorPalettes = {};
colorPalettes.light = {
	fg: '#000',
	bg: '#fff',

	fgContrast: 'var(--color-bg)',
	bgContrast: 'var(--color-fbg)',

	grey1: '#e3e3e3',
	grey2: '#a8a8a8',
	grey3: '#727272',
	grey4: '#3e3e3e',

	grey1Contrast: 'var(--color-fg)',
	grey2Contrast: 'var(--color-fg)',
	grey3Contrast: 'var(--color-bg)',
	grey4Contrast: 'var(--color-bg)',

	primary: 'var(--color-pink1)',
	primaryDark: 'var(--color-pink2)',
	primaryLight: 'var(--color-pink2)',

	primaryContrast: 'var(--color-fg)',
	primaryDarkContrast: 'var(--color-bg)',
	primaryLightContrast: 'var(--color-fg)',

	secondary: 'var(--color-purple1)',
	secondaryDark: 'var(--color-purple2)',
	secondaryLight: 'var(--color-purple2)',

	secondaryContrast: 'var(--color-bg)',
	secondaryDarkContrast: 'var(--color-bg)',
	secondaryLightContrast: 'var(--color-fg)',

	overlayBackground: 'rgba(0, 0, 0, .2)',
	loadingBackground: 'rgba(255, 255, 255, .1)',

	link: 'var(--color-fg)',
	linkActive: 'var(--color-primaryDark)',
	linkVisited: 'var(--color-primary)',

	selection: 'var(--color-secondaryContrast)',
	selectionBackground: 'var(--color-secondary)',

	success: '#3b9e3b',
	successContrast: '#fff',

	warning: '#ed8a15',
	warningContrast: '#000',

	error: '#ef1448',
	errorContrast: '#fff',

	pink1: '#ee2a69',
	pink2: '#f9b3a6',
	pink3: '#FEC5D4',

	orange1: '#ea6504',
	orange2: '#ec8c46',

	purple1: '#6443b5',
	purple2: '#ad90f4',

	yellow1: '#f6d106',
	yellow2: '#ecdf06',
	yellowText: '#000',

	blue1: '#0667e1',
	blue2: '#4dc4e1',

	ubs1: '#B2262C',
	ubs2: '#B2262C',

	huawei1: '#000',
	huawei2: '#000',

	twint1: '#4dc4e1',
	twint2: '#4dc4e1',
};

applyObjToTheme(theme, colorPalettes.light, 'color');

theme.getColors = ({ theme, key, inversed = false }) => css`
	color: ${theme.color[key + (inversed ? 'Contrast' : '')]};
	background: ${theme.color[key + (!inversed ? 'Contrast' : '')]};
`;

theme.getTint = ({ theme, key = 'pink' }) => {
	return {
		1: theme.color[key + '1'],
		2: theme.color[key + '2'],
		text: theme.color[key + 'Text'] || theme.color[key + '1'],
	};
};

theme = {
	...theme,

	resetButton: css`
		cursor: pointer;
		appearance: none;
		font: inherit;
		background: transparent;
		color: inherit;
		border: 0;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border-radius: 0;

		&::-moz-focus-inner {
			border: 0;
		}

		&:active,
		&:focus {
			outline: none;
		}

		&:disabled {
			cursor: default;
		}
	`,

	button: ({ theme, isPrimary = true }) => [
		theme.resetButton,
		css`
			text-decoration: none;
			color: ${isPrimary ? theme.color.primaryContrast : theme.color.fg};
			background-color: ${isPrimary ? theme.color.primary : theme.color.grey2Contrast};
			cursor: pointer;
			padding: 0.2em 0.4em;
			position: relative;
			border: 2px solid ${isPrimary ? theme.color.primary : theme.color.grey2};
			border-radius: 2em;

			&:disabled {
				background-color: ${theme.color.grey1};
				border-color: ${theme.color.grey1};
				color: ${theme.color.grey1Contrast};
				cursor: inherit;
			}

			&:not(:disabled) {
				&:active,
				&:focus,
				&:hover {
					outline: none;
					background-color: ${theme.color.primaryContrast};
					color: ${theme.color.primary};
					border-color: currentColor;
				}
			}
		`,
	],

	row: ({ theme }) => css`
		margin: calc(${theme.dimension.gap.s} / -2);

		@media (min-width: ${theme.breakpoint.m}) {
			margin: calc(${theme.dimension.gap.m} / -2);
		}

		@media (min-width: ${theme.breakpoint.l}) {
			margin: calc(${theme.dimension.gap.l} / -2);
		}
	`,

	col: ({ theme }) => css`
		margin: calc(${theme.dimension.gap.s} / 2);

		@media (min-width: ${theme.breakpoint.m}) {
			margin: calc(${theme.dimension.gap.m} / 2);
		}

		@media (min-width: ${theme.breakpoint.l}) {
			margin: calc(${theme.dimension.gap.l} / 2);
		}
	`,

	container: ({ theme, limit = true, narrow = false, padding = true, margin = false }) => [
		limit
			? css`
					max-width: ${narrow ? theme.dimension.maxWidthNarrow : theme.dimension.maxWidth};
					margin-left: auto;
					margin-right: auto;
			  `
			: null,
		padding
			? css`
					box-sizing: border-box;
					padding: ${theme.dimension.container.padding.s};

					@media (min-width: ${theme.breakpoint.m}) {
						padding: ${theme.dimension.container.padding.m};
					}

					@media (min-width: ${theme.breakpoint.l}) {
						padding: ${theme.dimension.container.padding.l};
					}
			  `
			: null,
		margin
			? css`
					margin-top: ${theme.dimension.container.margin.s};
					margin-bottom: ${theme.dimension.container.margin.s};

					@media (min-width: ${theme.breakpoint.m}) {
						margin-top: ${theme.dimension.container.margin.m};
						margin-bottom: ${theme.dimension.container.margin.m};
					}

					@media (min-width: ${theme.breakpoint.l}) {
						margin-top: ${theme.dimension.container.margin.l};
						margin-bottom: ${theme.dimension.container.margin.l};
					}
			  `
			: null,
	],

	createPadding: ({ theme, s, m, l }) => css`
		padding: ${s || theme.dimension.container.padding.s};

		@media (min-width: ${theme.breakpoint.m}) {
			padding: ${m || theme.dimension.container.padding.m};
		}

		@media (min-width: ${theme.breakpoint.l}) {
			padding: ${l || theme.dimension.container.padding.l};
		}
	`,

	createMargin: ({ theme, s = '1em', m = '1.4em', l = '2em' }) => css`
		margin: ${s};

		@media (min-width: ${theme.breakpoint.m}) {
			margin: ${m};
		}

		@media (min-width: ${theme.breakpoint.l}) {
			margin: ${l};
		}
	`,

	createGap: ({ theme, s, m, l }) => css`
		gap: ${(s !== undefined && s) || theme.dimension.gap.s};

		@media (min-width: ${theme.breakpoint.m}) {
			gap: ${(m !== undefined && m) || theme.dimension.gap.m};
		}

		@media (min-width: ${theme.breakpoint.l}) {
			gap: ${(l !== undefined && l) || theme.dimension.gap.l};
		}
	`,

	snapDiv: css`
		height: 80vh;
		-webkit-scroll-snap-align: start;
		scroll-snap-align: start;

		&:focus-visible {
			outline: none;
		}
	`,
};

export const globalStyles = [
	css`
		/**
	 * @license
	 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
	 *
	 * The fonts listed in this notice are subject to the End User License
	 * Agreement(s) entered into by the website owner. All other parties are
	 * explicitly restricted from using the Licensed Webfonts(s).
	 *
	 * You may obtain a valid license at the URLs below.
	 *
	 * Webfont: HelveticaLTWXX-Roman by Linotype
	 * URL: https://www.myfonts.com/fonts/linotype/helvetica/pro-regular/
	 * Copyright: Copyright © 2014 Monotype Imaging Inc. All rights reserved.
	 *
	 *
	 *
	 * © 2020 MyFonts Incn*/

		@font-face {
			font-family: 'DINNext';
			src: local('DINNextLightCondensed'), url('/_resources/font/DINNextLightCondensed/font.woff2') format('woff2'),
				url('/_resources/font/DINNextLightCondensed/font.woff') format('woff');
			font-style: normal;
			font-weight: 400;
			font-display: swap;
		}

		@font-face {
			font-family: 'DINNext';
			src: local('DINNextLightCondensed'), url('/_resources/font/DINNextMediumCondensed/font.woff2') format('woff2'),
				url('/_resources/font/DINNextMediumCondensed/font.woff') format('woff');
			font-style: normal;
			font-weight: 600;
			font-display: swap;
		}

		@font-face {
			font-family: 'DINNext';
			src: local('DINNextBlack'), url('/_resources/font/DINNextBlack/font.woff2') format('woff2'),
				url('/_resources/font/DINNextBlack/font.woff') format('woff');
			font-style: normal;
			font-weight: 800;
			font-display: swap;
		}

		@font-face {
			font-family: 'FFScala';
			src: local('FFScalaRegular'), url('/_resources/font/FFScalaRegular/font.woff2') format('woff2'),
				url('/_resources/font/FFScalaRegular/font.woff') format('woff');
			font-style: normal;
			font-weight: 400;
			font-display: swap;
		}

		@font-face {
			font-family: 'FFScala';
			src: local('FFScalaItalic'), url('/_resources/font/FFScalaItalic/font.woff2') format('woff2'),
				url('/_resources/font/FFScalaItalic/font.woff') format('woff');
			font-style: italic;
			font-weight: 400;
			font-display: swap;
		}

		:root {
			${applyObjToCss(colorPalettes.light, 'color')}
			${applyObjToCss(zIndex, 'zIndex')}
            ${applyObjToCss(dimension, 'dimension')}
            ${applyObjToCss(font, 'font')}
		}

		#root {
			height: 100%;
		}

		html,
		body {
			height: 100%;
			padding: 0;
			margin: 0;
			font-size: 100%;
			font-family: ${theme.font.default.family};
			font-weight: ${theme.font.default.weight.regular};
			font-style: normal;
			line-height: 1.2;
			-webkit-scroll-snap-type: y mandatory;
			scroll-snap-type: y mandatory;
			overscroll-behavior: none;
			scroll-behavior: auto;
		}

		html.nosnap,
		html.nosnap body {
			-webkit-scroll-snap-type: none;
			scroll-snap-type: none;
		}

		body {
			color: ${theme.color.fg};
			background-color: ${theme.color.bg};
			-moz-osx-font-smoothing: grayscale;
			font-size-adjust: none;
			-webkit-text-size-adjust: none;
			box-sizing: border-box;
			font-size: 100%;
		}

		::selection {
			background: ${theme.color.selectionBackground};
			color: ${theme.color.selection};
			text-shadow: none;
		}

		a {
			color: ${theme.color.link};

			&:active {
				color: ${theme.color.linkActive};
			}

			&:visited {
				color: ${theme.color.linkVisited};
			}
		}
	`,
];

export default theme;
