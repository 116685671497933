/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useMemo, useState, useCallback, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useHover } from 'react-aria';

import usePageNavigation from './usePageNavigation';
import { Typography } from '../typography';
import { usePage } from '../../api/use';

const Stripe = ({ tint, zIndex, isActive }) => {
	return (
		<motion.div
			initial={{ opacity: 0, skewY: 16, x: '1em' }}
			animate={{ opacity: 1, x: isActive ? '-.25em' : '0em', y: isActive ? '-.075em' : '0em' }}
			exit={{ opacity: 0, x: '1em' }}
			css={(theme) => css`
				position: absolute;
				z-index: ${zIndex};
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				background: ${tint ? theme.getTint({ theme, key: tint })[1] : 'transparent'};
				border: 1px solid rgba(0, 0, 0, 0.4);
				border-top-color: rgba(255, 255, 255, 0.4);
				border-left-color: rgba(255, 255, 255, 0.4);
			`}
		/>
	);
};

const Label = ({ label }) => (
	<motion.div
		key="label"
		initial={{ opacity: 0, y: '100%' }}
		animate={{ opacity: 1, y: '0%' }}
		exit={{ opacity: 0, y: '100%' }}
		css={(theme) => css`
			position: absolute;
			z-index: -1;
			right: 1.6em;
			top: 50%;
			pointer-events: none;
			color: ${theme.color.fg};
		`}
	>
		<Typography
			font="caption"
			size="l"
			lineHeight={1}
			weight="bold"
			css={css`
				min-width: 10em;
				max-width: 80vw;
				margin-top: -0.5em;
				background: rgba(255, 255, 255, 0.6);
				padding: 0.1em 0.2em 0;
				border-radius: 0.2em;
			`}
		>
			{label}
		</Typography>
	</motion.div>
);

const SubDot = ({ id, hash, label, tint }) => {
	const { hiliteIdSub } = usePageNavigation();
	const [hover, setHover] = useState(false);

	const { hoverProps } = useHover({
		onHoverStart: (e) => setHover(true),
		onHoverEnd: (e) => setHover(false),
	});

	return (
		<Link
			to={'#' + hash}
			css={css`
				flex: 1 1 auto;
				display: flex;
				align-items: center;
				cursor: pointer;
				position: relative;
			`}
			{...hoverProps}
		>
			<Stripe tint={tint} isActive={hover || id === hiliteIdSub} zIndex={hover ? 2 : id === hiliteIdSub ? 1 : 0} />
			{!!hover && <Label label={label} />}
		</Link>
	);
};

const Dot = memo(({ id, hash, label, height, tint, ...props }) => {
	const { subItems, hiliteId, hiliteIdSub } = usePageNavigation();
	const [hover, setHover] = useState(false);

	const { hoverProps } = useHover({
		onHoverStart: (e) => setHover(true),
		onHoverEnd: (e) => setHover(false),
	});

	const mySubItems = useMemo(() => (!!subItems[id] && !!subItems[id].length ? subItems[id] : false), [subItems, id]);
	const isCurrent = useMemo(() => hiliteId === id, [hiliteId, id]);

	/*
	 * let's make sure that the label is hidden when button hides
	 */
	useEffect(() => {
		if (hiliteIdSub || (isCurrent && mySubItems)) {
			setHover(false);
		}
	}, [setHover, mySubItems, isCurrent, hiliteIdSub]);

	return (
		<div
			css={css`
				flex: 1 1 ${height}%;
				width: 1em;
				position: relative;
				margin: 0.2em;
				transition: all 0.3s ease;
			`}
			{...props}
		>
			<AnimatePresence>
				{(!mySubItems || !isCurrent) && (
					<motion.div
						key="dot"
						initial={{ opacity: 0, x: '100%' }}
						animate={{ opacity: 1, x: '0%' }}
						exit={{ opacity: 0, x: '100%' }}
						css={css`
							height: 100%;
						`}
					>
						<Link
							to={'#' + hash}
							css={(theme) => css`
								display: block;
								width: 1em;
								height: 100%;
								color: ${theme.getTint({ theme, key: tint })[1]};
								cursor: pointer;
							`}
							{...hoverProps}
						>
							<Stripe isActive={hover || isCurrent} tint={tint} />
						</Link>
					</motion.div>
				)}
				{!!hover && <Label label={label} />}
				{!!mySubItems && isCurrent && (
					<motion.div
						key="sub"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						css={css`
							position: absolute;
							right: 0;
							left: 0;
							top: 0;
							height: 100%;
							display: flex;
							flex-direction: column;
						`}
					>
						{mySubItems.map((item) => (
							<SubDot key={item.id} id={item.id} hash={item.hash} tint={item.tint} label={item.label} />
						))}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
});

const BarLabel = (props) => (
	<motion.div initial={{ x: '100%' }} animate={{ x: '0%' }}>
		<Typography
			size="s"
			font="caption"
			weight="bold"
			css={css`
				text-align: center;
				margin: 0.4em 0;
			`}
			{...props}
		/>
	</motion.div>
);
const PageNavigation = () => {
	const { items } = usePageNavigation();
	const {
		vitality: { loaded },
	} = usePage();
	return (
		!!loaded &&
		!!items.length && (
			<div
				css={css`
					position: fixed;
					z-index: var(--zIndex-pageNav);
					right: 0;
					top: 25%;
					bottom: 25%;
					display: flex;
					flex-direction: column;
				`}
			>
				<BarLabel>1</BarLabel>
				{items.map((item) => (
					<Dot key={item.id} id={item.id} hash={item.hash} height={item.height} tint={item.tint} label={item.label} />
				))}
				<BarLabel>91</BarLabel>
			</div>
		)
	);
};

export default memo(PageNavigation);
