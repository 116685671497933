import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import useWindowSize from './useWindowSize';

const calcRootMargin = (windowHeight, position) => {
	const top = Math.round(windowHeight * position);
	return `${-top}px 0% ${-(windowHeight - top)}px`;
};

const useSectionTrigger = ({ position = 0.5 } = {}) => {
	const windowSize = useWindowSize();
	const [rootMargin, setRootMargin] = useState(calcRootMargin(windowSize.height, position));

	useEffect(() => {
		setRootMargin(calcRootMargin(windowSize.height, position));
	}, [windowSize.height, position]);

	const { ref, inView, entry } = useInView({
		delay: 400,
		triggerOnce: false,
		rootMargin,
	});

	return { ref, inView, entry };
};

export default useSectionTrigger;
