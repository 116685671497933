import { useEffect, useCallback, useRef, createContext, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import stringToReadableURL from 'string-to-readable-url';

import useSectionTrigger from '../../hooks/useSectionTrigger';

export const PageNavigationContext = createContext();

const items = [];
const subItems = {};

export const PageNavigationProvider = ({ children }) => {
	const { hash } = useLocation();
	const navigate = useNavigate();

	const [hiliteId, setHiliteId] = useState(0);
	const [hiliteIdSub, setHiliteIdSub] = useState(0);
	const [_items, setItems] = useState(items);
	const [_subItems, setSubItems] = useState(subItems);

	const setItemsEnhanced = useCallback(
		(items) => {
			const allHeight = items.reduce((prev, curr) => prev + curr.domNode.getBoundingClientRect().height, 0);
			setItems(
				items
					.map((it) => ({
						...it,
						top: it.domNode.getBoundingClientRect().top,
						height: (100 / allHeight) * it.domNode.getBoundingClientRect().height,
					}))
					.sort((a, b) => a.top - b.top)
			);
		},
		[setItems]
	);

	const hiliteItemById = useCallback(
		(id, subId) => {
			if (id) {
				if (hiliteId !== id) setHiliteId(id);
			}
			if (subId) {
				if (hiliteIdSub !== subId) setHiliteIdSub(subId);
			}
		},
		[hiliteId, setHiliteId, hiliteIdSub, setHiliteIdSub]
	);

	const resolveItem = useCallback(
		(id, subId, ident = 'id') =>
			id
				? items.find((item) => item[ident] === id)
				: Object.values(subItems)
						.map((arr) => arr.find((item) => item[ident] === subId))
						.find((it) => !!it),
		[]
	);

	const scrollTimeout = useRef(0);
	const scrollToDomNode = useCallback((domNode) => {
		if (!domNode) return;
		clearTimeout(scrollTimeout.current);
		scrollTimeout.current = setTimeout(() => {
			const rect = domNode.getBoundingClientRect();

			document.documentElement.classList.add('nosnap');
			requestAnimationFrame(() => {
				window.scrollTo(0, Math.round(rect.top + window.pageYOffset));
				requestAnimationFrame(() => {
					document.documentElement.classList.remove('nosnap');
				});
			});
		}, 1);
	}, []);

	const scrollToItemById = useCallback(
		(id, subId) => {
			scrollToDomNode((resolveItem(id, subId) || {}).domNode);
		},
		[resolveItem, scrollToDomNode]
	);

	const clickItemById = useCallback(
		(id, subId) => {
			const hash = (resolveItem(id, subId) || {}).hash;
			if (hash) {
				navigate('#' + hash);
			}
		},
		[navigate, resolveItem]
	);

	let hashScrollTimeout = useRef(0);
	const scrollByHash = useCallback(() => {
		clearTimeout(hashScrollTimeout.current);
		if (!items || !hash) return;
		hashScrollTimeout.current = setTimeout(() => {
			const myHash = hash.substring(1);
			const item = items.find((it) => it.hash === myHash);
			if (item) {
				scrollToItemById(item.id);
				return;
			}
			const subItem = Object.values(subItems)
				.map((arr) => arr.find((it) => it.hash === myHash))
				.find((it) => !!it);

			if (subItem) {
				scrollToItemById(null, subItem.id);
			}
		}, 60);
	}, [scrollToItemById, hash]);

	useEffect(() => {
		scrollByHash(hash);
	}, [scrollByHash, hash]);

	const checkHilite = useCallback(() => {
		let closestTop, closestId;
		items.forEach(({ id, domNode }) => {
			const rect = domNode.getBoundingClientRect();
			if (closestTop === undefined || closestTop > Math.abs(rect.top)) {
				closestTop = Math.abs(rect.top);
				closestId = id;
			}
		});
		if (hiliteId !== closestId) setHiliteId(closestId);

		closestTop = null;
		closestId = null;
		Object.values(subItems).map((arr) =>
			arr.forEach(({ id, domNode }) => {
				const rect = domNode.getBoundingClientRect();
				if (closestTop === undefined || closestTop > Math.abs(rect.top)) {
					closestTop = Math.abs(rect.top);
					closestId = id;
				}
			})
		);
		if (hiliteIdSub !== closestId) setHiliteIdSub(closestId);
	}, [hiliteId, setHiliteId, hiliteIdSub, setHiliteIdSub]);

	const readableHash = useCallback(
		(str) =>
			stringToReadableURL(
				str
					.toLowerCase()
					.replaceAll('ä', 'ae')
					.replaceAll('ö', 'oe')
					.replaceAll('ü', 'ue')
					.replaceAll('è', 'e')
					.replaceAll('à', 'a')
					.replaceAll('é', 'e')
			),
		[]
	);

	const addItem = useCallback(
		(element, domNode) => {
			if (items.find((item) => item.id === element.id)) return;
			const hash = readableHash(element.hash || element.label || element.id);
			domNode.id = hash;
			items.push({ ...element, domNode, hash });
			setItemsEnhanced(items);
			scrollByHash();
		},
		[setItemsEnhanced, scrollByHash, readableHash]
	);

	const addSubItem = useCallback(
		(parentId, element, domNode) => {
			if (subItems[parentId] && subItems[parentId].find((item) => item.id === element.id)) return;
			if (!subItems[parentId]) {
				subItems[parentId] = [];
			}
			const hash = readableHash(element.hash || element.label || element.id);
			domNode.id = hash;
			subItems[parentId].push({ ...element, domNode, hash });
			setSubItems({ ...subItems });
			scrollByHash();
		},
		[setSubItems, scrollByHash, readableHash]
	);

	const removeItem = useCallback(
		(element) => {
			const idx = items.map((item) => item.id).indexOf(element.id);
			if (idx === -1) return;
			items.splice(idx, 1);
			setItems([...items]);
		},
		[setItems]
	);

	const removeSubItem = useCallback(
		(parentId, element) => {
			if (!subItems[parentId]) return;
			const idx = subItems[parentId].map((item) => item.id).indexOf(element.id);
			if (idx === -1) return;
			subItems[parentId].splice(idx, 1);
			setSubItems({ ...subItems });
		},
		[setSubItems]
	);

	return (
		<PageNavigationContext.Provider
			value={{
				items: _items,
				subItems: _subItems,
				addItem,
				addSubItem,
				removeSubItem,
				removeItem,
				hiliteId,
				hiliteIdSub,
				hiliteItemById,
				checkHilite,
				scrollToItemById,
				clickItemById,
			}}
		>
			{children}
		</PageNavigationContext.Provider>
	);
};

export const useNavTrigger = ({ id, tint, label, hash, noSubItems }) => {
	const ref = useRef();
	const { inView, ref: inViewRef } = useSectionTrigger();
	const { addItem, removeItem, hiliteItemById } = usePageNavigation();

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[ref, inViewRef]
	);

	useEffect(() => {
		addItem({ id, tint, label, hash, noSubItems }, ref.current);
		return () => {
			removeItem({ id });
		};
	}, [addItem, removeItem, ref, id, tint, label, hash, noSubItems]);

	useEffect(() => {
		if (!inView) return;
		hiliteItemById(id);
	}, [id, inView, hiliteItemById]);

	return { ref: setRefs, inView };
};

export const useSubnavTrigger = ({ parentNav, id, tint, label, hash }) => {
	const ref = useRef();
	const { inView, ref: inViewRef } = useSectionTrigger();
	const { addSubItem, removeSubItem, hiliteItemById } = usePageNavigation();

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[ref, inViewRef]
	);

	useEffect(() => {
		addSubItem(parentNav.id, { id, tint, label, hash }, ref.current);
		return () => {
			removeSubItem(parentNav.id, { id });
		};
	}, [addSubItem, removeSubItem, parentNav.id, ref, id, tint, label, hash]);

	useEffect(() => {
		if (!inView) return;
		hiliteItemById(null, id);
	}, [id, inView, hiliteItemById]);

	return { ref: setRefs, inView };
};

const usePageNavigation = () => {
	return useContext(PageNavigationContext);
};

export default usePageNavigation;
