/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo } from 'react';

import { useHomeHref } from '../../api/use';
import Link from '../navigation/Link';
import Logo from './Logo';
import LanguageNavigation from '../navigation/LanguageNavigation';
import Container from './Container';
import usePageNavigation from '../navigation/usePageNavigation';

const Header = memo(({ children, isFixed = true }) => {
	const { hiliteId } = usePageNavigation();
	const homeHref = useHomeHref();

	return (
		<div
			css={(theme) => css`
				z-index: ${theme.zIndex.header};
				position: ${isFixed ? 'fixed' : 'static'};
				left: 0;
				top: 0;
				right: 0;
				pointer-events: none;
			`}
		>
			<Container
				limit={false}
				margin={false}
				css={css`
					display: flex;
					justify-content: space-between;
					align-items: center;
				`}
			>
				<Link
					href={homeHref}
					title="Homepage"
					css={css`
						display: block;
						color: currentColor !important;
						pointer-events: all;

						transition: width ease 0.4s;
						width: ${isFixed && ![0, 'header'].includes(hiliteId) ? '12em' : '50%'};
						max-width: 20em;
					`}
				>
					<Logo />
				</Link>
				<LanguageNavigation />
				{children}
			</Container>
		</div>
	);
});

export default Header;
