/** @jsxImportSource @emotion/react */
import { memo } from 'react';

const Container = ({
	as: Component = 'div',
	limit = true,
	narrow = false,
	padding = true,
	margin = true,
	...props
}) => {
	return <Component css={(theme) => theme.container({ theme, limit, narrow, padding, margin })} {...props} />;
};

export default memo(Container);
