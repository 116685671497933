/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';

import { useSite, usePage } from '../../api/use';

import { Typography } from '../typography';

const Item = memo((props) => (
	<div
		css={css`
			position: relative;
			overflow: hidden;
			white-space: nowrap;
			padding: 0 0.5em;
			pointer-events: all;
		`}
	>
		<Typography
			as={NavLink}
			font="caption"
			weight="bold"
			lineHeight="1"
			css={(theme) => css`
				display: block;
				color: ${theme.color.fg}!important;
			`}
			{...props}
		/>
	</div>
));

const LanguageNavigation = (props) => {
	const { lang } = useSite();
	const { lang: pageLang } = usePage();

	return (
		<nav
			css={css`
				margin-left: auto;
				flex: 0 0 auto;
				position: relative;
				display: flex;
				justify-content: flex-end;
			`}
			{...props}
		>
			{!!lang &&
				lang.map(({ locale, label, home }) => (
					<Item key={locale} to={(pageLang && pageLang.find(({ locale: l }) => l === locale)?.link) || home}>
						{label}
					</Item>
				))}
		</nav>
	);
};

export default memo(LanguageNavigation);
