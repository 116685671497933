/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo } from 'react';

const Burger = ({ isOpen, onClick }) => {
	return (
		<button
			onClick={onClick}
			css={(theme) => [
				theme.resetButton,
				css`
					display: block;
					margin-left: 1em;
					margin-top: -0.4em;
				`,
			]}
		>
			<svg
				viewBox="0 0 16 16"
				width="16"
				height="16"
				css={css`
					pointer-events: all;
					display: block;
					width: 1.8em;
					height: 1.8em;
					stroke: currentColor;
					stroke-width: 2;
				`}
			>
				{!isOpen ? (
					<g>
						<line x1="0" y1="2" x2="16" y2="2" />
						<line x1="0" y1="8" x2="16" y2="8" />
						<line x1="0" y1="14" x2="16" y2="14" />
					</g>
				) : (
					<g>
						<line x1="2" y1="2" x2="14" y2="14" />
						<line x1="2" y1="14" x2="14" y2="2" />
					</g>
				)}
			</svg>
		</button>
	);
};

export default memo(Burger);
